import React, { useState } from 'react';
import { classes } from './PreviewTooltip.st.css';
import { PreviewTooltipDataHooks } from './dataHooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Tooltip, TooltipSkin } from 'wix-ui-tpa/cssVars';
import useTimeout from '../../Hooks/useTimeout';

export interface PreviewTooltipProps {
  content: string;
}
const PreviewTooltip: React.FC<PreviewTooltipProps> = ({
  content,
  children,
}) => {
  // todo: change translated lang to account lang for the consumers (content prop)
  const { isPreview } = useEnvironment();
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const timeout = useTimeout();

  const showTooltip = () => {
    setIsTooltipShown(true);
    timeout.set(() => {
      hideTooltip();
    }, 3000);
  };

  const hideTooltip = () => {
    timeout.clear();
    setIsTooltipShown(false);
  };

  return (
    <>
      {isPreview ? (
        <div
          className={classes.root}
          onClick={showTooltip}
          data-hook={PreviewTooltipDataHooks.TOOLTIP_WRAPPER}
        >
          <Tooltip
            skin={TooltipSkin.Wired}
            content={content}
            className={classes.tooltip}
            data-hook={PreviewTooltipDataHooks.TOOLTIP}
            minWidth={200}
            shown={isTooltipShown}
            onClickOutside={hideTooltip}
          >
            {children}
          </Tooltip>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default PreviewTooltip;
