import React from 'react';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentSummary.st.css';
import Text, { TextType } from '../../Text/Text';
import { PaymentSummaryDataHook } from './dataHooks';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
  ServicePaymentDetails,
} from '../../../../types/types';
import { Divider, Spinner } from 'wix-ui-tpa/cssVars';
import { TagName } from '../../../../utils/tagName.const';
import { PaymentDetails } from '../PaymentDetails/PaymentDetails';
import { getContent } from '../../../../utils/content/content';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { CouponDetails } from '@wix/ambassador-checkout-server/types';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { PriceItem } from './PriceItem/PriceItem';
import { FormStatus } from '../../../../types/form-state';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';
import {
  DynamicPriceInfo,
  PaymentDetails as PaymentDetailsType,
} from '../../../../types/dynamicPrice';

export type PaymentSummaryProps = {
  servicePayment: ServicePaymentDetails;
  selectedPaymentOption: PaymentOption;
  dateRegionalSettingsLocale: string;
  numberOfParticipants: number;
  status: FormStatus;
  showPricePerParticipant: boolean;
  appliedCoupon?: CouponDetails;
  dynamicPriceInfo?: DynamicPriceInfo;
};

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  servicePayment,
  selectedPaymentOption,
  dateRegionalSettingsLocale,
  numberOfParticipants,
  appliedCoupon,
  showPricePerParticipant,
  status,
  dynamicPriceInfo,
}) => {
  const { t } = useTranslation();
  const { isBookingsOnEcom } = useFormComponentContext();
  const settings = useSettings();

  const {
    price,
    priceText,
    currency,
    tax,
    subtotalPerParticipant,
    payNow,
    payLater,
    minCharge: deposit,
    totalPrice,
  } = servicePayment;

  const isPricingPlanSelected =
    !selectedPaymentOption?.disabled &&
    ![
      ReservedPaymentOptionIds.SingleSession,
      ReservedPaymentOptionIds.BuyAPricingPlan,
    ].includes(selectedPaymentOption?.id as ReservedPaymentOptionIds);

  const serviceHasPrice = price > 0 || !!priceText;
  const showSingleSessionSummary =
    selectedPaymentOption?.id === ReservedPaymentOptionIds.SingleSession &&
    serviceHasPrice;

  const shouldShowPaymentSummary =
    showSingleSessionSummary || isPricingPlanSelected;

  const sectionTitle = getContent({
    settings,
    settingsParam: settingsParams.summaryPaymentSectionTitle,
  });

  const couponDiscount = Number(appliedCoupon?.couponDiscount) || 0;
  const isProcessingPaymentDetails =
    status === FormStatus.PROCESSING_PAYMENT_DETAILS;

  let subtotalItems: PaymentDetailsType[] = [];
  if (dynamicPriceInfo) {
    const defaultPrice = dynamicPriceInfo.defaultPrice;
    subtotalItems = (dynamicPriceInfo.paymentDetails || []).map(
      ({ price: choicePrice, ...rest }) => ({
        ...rest,
        price: choicePrice || {
          currency: defaultPrice!.currency,
          value: defaultPrice!.amount,
        },
      }),
    );
  }

  const priceBreakDownItems = getPriceBreakDownItems({
    isBookingsOnEcom,
    locale: dateRegionalSettingsLocale,
    numberOfParticipants,
    showPricePerParticipant,
    couponDiscount,
    tax,
    subtotalPerParticipant,
    price,
    currency,
    t,
    subtotalItems,
  });

  const totalPriceItems = getTotalPriceItems({
    locale: dateRegionalSettingsLocale,
    price,
    totalPrice,
    payNow: payNow!,
    payLater: payLater!,
    deposit,
    t,
    currency,
    isBookingsOnEcom,
  });

  return shouldShowPaymentSummary ? (
    <div
      className={st(classes.root, { isProcessingPaymentDetails })}
      data-hook={PaymentSummaryDataHook.Payment_Summary}
    >
      <Divider className={classes.divider} />
      <Text
        tagName={TagName.H3}
        type={TextType.Primary}
        className={classes.title}
        data-hook={PaymentSummaryDataHook.Title}
      >
        {sectionTitle}
      </Text>
      {showSingleSessionSummary ? (
        <div className={classes.singleSession}>
          {isProcessingPaymentDetails ? (
            <div className={classes.spinner}>
              <Spinner
                isCentered
                diameter={24}
                data-hook={PaymentSummaryDataHook.Spinner}
              />
            </div>
          ) : null}
          <div className={classes.details}>
            {priceText ? (
              <div>
                <Text
                  type={TextType.Secondary}
                  data-hook={PaymentSummaryDataHook.Custom_Price}
                >
                  {priceText}
                </Text>
              </div>
            ) : (
              <>
                {priceBreakDownItems.map((item, index) => (
                  <PriceItem
                    key={index}
                    type={TextType.Secondary}
                    labels={item.labels}
                    price={item.price}
                    ariaPrice={item.ariaPrice}
                    dataHook={item.dataHook}
                  />
                ))}
                {priceBreakDownItems.length ? (
                  <Divider className={classes.divider} />
                ) : null}
                {totalPriceItems.map((totalItem, index) => (
                  <PriceItem
                    key={index}
                    type={TextType.Primary}
                    labels={totalItem.labels}
                    price={totalItem.price}
                    ariaPrice={totalItem.ariaPrice}
                    dataHook={totalItem.dataHook}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      ) : (
        <PaymentDetails paymentOption={selectedPaymentOption} />
      )}
    </div>
  ) : null;
};

const getPriceBreakDownItems = ({
  isBookingsOnEcom,
  numberOfParticipants,
  showPricePerParticipant,
  couponDiscount,
  tax,
  price,
  subtotalPerParticipant,
  currency,
  locale,
  t,
  subtotalItems,
}: {
  isBookingsOnEcom: boolean;
  numberOfParticipants: number;
  showPricePerParticipant: boolean;
  couponDiscount?: number;
  tax?: number;
  subtotalPerParticipant?: number;
  price: number;
  currency: string;
  locale: string;
  t: TFunction;
  subtotalItems: PaymentDetailsType[];
}) => {
  const isSubtotalNeeded =
    showPricePerParticipant ||
    couponDiscount ||
    tax ||
    subtotalItems.length > 0;
  const shouldShowSubtotal = isBookingsOnEcom
    ? subtotalPerParticipant && isSubtotalNeeded
    : isSubtotalNeeded;
  const calculatedSubtotalPerParticipant = isBookingsOnEcom
    ? subtotalPerParticipant!
    : price;
  return [
    ...(shouldShowSubtotal
      ? [
          {
            labels: [
              t('app.payment.summary.subtotal.text'),
              ...(showPricePerParticipant && !subtotalItems.length
                ? [
                    t('app.payment.summary.number-of-participants.text', {
                      numberOfParticipants,
                      price: PriceUtils.getFormattedCurrency({
                        price: calculatedSubtotalPerParticipant,
                        currency,
                        locale,
                      }),
                    }),
                  ]
                : []),
              ...subtotalItems.map(
                ({
                  price: choicePrice,
                  numberOfParticipants: choiceNumberOfParticipants,
                  choice,
                }) =>
                  t('app.payment.summary.number-of-custom-choice.text', {
                    numberOfParticipants: choiceNumberOfParticipants,
                    choice,
                    price: PriceUtils.getFormattedCurrency({
                      price: Number(choicePrice!.value),
                      currency: choicePrice!.currency!,
                      locale,
                    }),
                  }),
              ),
            ],
            price: PriceUtils.getFormattedCurrency({
              price: calculatedSubtotalPerParticipant,
              currency,
              locale,
              quantity: numberOfParticipants,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: calculatedSubtotalPerParticipant,
              currency,
              locale,
              quantity: numberOfParticipants,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Subtotal,
          },
        ]
      : []),
    ...(couponDiscount
      ? [
          {
            labels: [t('app.payment.summary.promo-code.text')],
            price: PriceUtils.getFormattedCurrency({
              price: -couponDiscount,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: -couponDiscount,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Coupon_Discount,
          },
        ]
      : []),
    ...(tax
      ? [
          {
            labels: [t('app.payment.summary.tax.text')],
            price: PriceUtils.getFormattedCurrency({
              price: tax,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: tax,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Tax,
          },
        ]
      : []),
  ];
};

const getTotalPriceItems = ({
  price,
  totalPrice,
  payNow,
  payLater,
  deposit,
  currency,
  locale,
  t,
  isBookingsOnEcom,
}: {
  price: number;
  totalPrice: number;
  payNow: number;
  payLater: number;
  deposit: number;
  currency: string;
  locale: string;
  t: TFunction;
  isBookingsOnEcom: boolean;
}) => {
  let payNowAmount = 0;
  let payLaterAmount = 0;

  if (isBookingsOnEcom) {
    payNowAmount = payLater ? payNow : 0;
    payLaterAmount = payLater;
  } else {
    payNowAmount = deposit;
    payLaterAmount = deposit ? totalPrice - deposit : 0;
  }

  const shouldShowPayLaterSection =
    payLaterAmount && payLaterAmount !== totalPrice;

  return [
    ...(price
      ? [
          {
            labels: [t('app.payment.summary.total.text')],
            price: PriceUtils.getFormattedCurrency({
              price: totalPrice,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: totalPrice,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Total_Price,
          },
        ]
      : []),
    ...(payNowAmount
      ? [
          {
            labels: [t('app.payment.summary.pay-now.text')],
            price: PriceUtils.getFormattedCurrency({
              price: payNowAmount,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: payNowAmount,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Deposit,
          },
        ]
      : []),
    ...(shouldShowPayLaterSection
      ? [
          {
            labels: [t('app.payment.summary.pay-later.text')],
            price: PriceUtils.getFormattedCurrency({
              price: payLaterAmount,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: payLaterAmount,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Pay_Later,
          },
        ]
      : []),
  ];
};
