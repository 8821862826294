import React from 'react';
import { classes } from './PaymentDetails.st.css';
import Text, { TextType } from '../../Text/Text';
import { PaymentOption } from '../../../../types/types';

export interface PaymentDetailsProps {
  paymentOption: PaymentOption;
}

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  paymentOption,
}) => {
  const { label, suffix, validUntil } = paymentOption;
  return (
    <div data-hook="plan-details">
      <div className={classes.paymentDetails}>
        <Text type={TextType.Secondary}>{label}</Text>
        {suffix ? (
          <Text className={classes.planSlotsLeft} type={TextType.Secondary}>
            {suffix}
          </Text>
        ) : null}
      </div>
      <div>
        <Text type={TextType.Secondary}>{validUntil}</Text>
      </div>
    </div>
  );
};
