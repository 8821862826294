import React from 'react';
import { PaymentTypeDataHooks } from './dataHooks';
import {
  TFunction,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentTypeSelection.st.css';
import { useFormActions } from '../../../Hooks/useFormActions';
import { Dropdown } from 'wix-ui-tpa/cssVars';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../../utils/content/content';
import settingsParams from '../../../settingsParams';
import { ISettingsContextValue } from '@wix/tpa-settings';
import Text from '../../../Text/Text';
import { TagName } from '../../../../../utils/tagName.const';
import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';

export type PaymentTypeSelectionProps = {
  selectedPaymentType: SelectedPaymentOption;
  paymentTypes: SelectedPaymentOption[];
};

export const PaymentTypeSelection: React.FC<PaymentTypeSelectionProps> = ({
  selectedPaymentType,
  paymentTypes,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { setSelectedPaymentType } = useFormActions();
  const dropdownOptions = paymentTypes
    .filter((paymentType) =>
      [SelectedPaymentOption.OFFLINE, SelectedPaymentOption.ONLINE].includes(
        paymentType,
      ),
    )
    .map((paymentType) => ({
      id: paymentType,
      value: mapTypeToDropdownValue(paymentType, t, settings),
      isSelectable: true,
    }));
  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={PaymentTypeDataHooks.Payment_Type_Selection}
    >
      <Text
        className={classes.label}
        data-hook={PaymentTypeDataHooks.Payment_Type_Selector_Label}
        tagName={TagName.Span}
      >
        {getContent({
          settings,
          settingsParam: settingsParams.selectPaymentTypeLabel,
        })}
      </Text>
      <Dropdown
        className={classes.PaymentTypeSelector}
        initialSelectedId={selectedPaymentType}
        options={dropdownOptions}
        onChange={(option) =>
          setSelectedPaymentType(option.id as SelectedPaymentOption)
        }
        data-hook={PaymentTypeDataHooks.Dropdown}
      />
    </div>
  );
};

const mapTypeToDropdownValue = (
  type: SelectedPaymentOption,
  t: TFunction,
  settings: Partial<ISettingsContextValue>,
): string => {
  const settingsParam =
    type === SelectedPaymentOption.ONLINE
      ? settingsParams.payOnlineText
      : settingsParams.payOfflineText;
  return getContent({
    settings,
    settingsParam,
  });
};
