import React from 'react';
import { TagName } from '../../../../utils/tagName.const';
import { PaymentSelectionHook } from './dataHooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentSelection.st.css';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../types/types';
import { PaymentTypeSelection } from './PaymentTypeSelection/PaymentTypeSelection';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';
import { PaymentOptionSelection } from './PaymentOptionSelection/PaymentOptionSelection';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';

export type PaymentSelectionProps = {
  selectedPaymentOptionId: string;
  paymentOptions: PaymentOption[];
  numberOfParticipants: number;
  selectedPaymentType: SelectedPaymentOption;
  paymentTypes: SelectedPaymentOption[];
  dateRegionalSettingsLocale: string;
};

export const PaymentSelection: React.FC<PaymentSelectionProps> = ({
  paymentOptions,
  selectedPaymentOptionId,
  numberOfParticipants,
  selectedPaymentType,
  paymentTypes,
  dateRegionalSettingsLocale,
}) => {
  const { isMobile } = useEnvironment();
  const { isBookingsOnEcom } = useFormComponentContext();
  const settings = useSettings();
  const shouldShowPaymentMethodSelection = paymentOptions.length > 1;

  const settingsParamForTitle = isBookingsOnEcom
    ? settingsParams.selectPaymentTitle
    : settingsParams.selectPaymentMethodTitle;
  const title = getContent({
    settings,
    settingsParam: settingsParamForTitle,
  });

  const shouldShowPaymentTypeSelection =
    isBookingsOnEcom &&
    paymentTypes.includes(SelectedPaymentOption.OFFLINE) &&
    paymentTypes.includes(SelectedPaymentOption.ONLINE) &&
    selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;

  const shouldShowSection =
    shouldShowPaymentMethodSelection || shouldShowPaymentTypeSelection;
  return shouldShowSection ? (
    <fieldset
      className={st(classes.root, { isMobile })}
      data-hook={PaymentSelectionHook.Payment_Selection}
    >
      <SectionTitle
        label={title}
        data-hook={PaymentSelectionHook.Title}
        tagName={TagName.Legend}
      />
      {shouldShowPaymentMethodSelection && (
        <PaymentOptionSelection
          paymentOptions={paymentOptions}
          selectedPaymentOptionId={selectedPaymentOptionId}
          dateRegionalSettingsLocale={dateRegionalSettingsLocale}
          numberOfParticipants={numberOfParticipants}
        />
      )}
      {shouldShowPaymentTypeSelection && (
        <PaymentTypeSelection
          selectedPaymentType={selectedPaymentType}
          paymentTypes={paymentTypes}
          data-hook={PaymentSelectionHook.Payment_Type}
        />
      )}
    </fieldset>
  ) : null;
};
