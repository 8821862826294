import React from 'react';
import {
  TFunction,
  useTranslation,
  useEnvironment,
} from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentOptionSelection.st.css';
import { useFormActions } from '../../../Hooks/useFormActions';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../../utils/content/content';
import settingsParams from '../../../settingsParams';
import Text from '../../../Text/Text';
import { TagName } from '../../../../../utils/tagName.const';
import { PaymentOptionsSelectionDataHooks } from './dataHooks';
import { PaymentOption as PaymentOptionComponent } from '../PaymentOption/PaymentOption';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../../types/types';
import {
  SectionMessage,
  SectionMessageType,
} from '../../SectionMessage/SectionMessage';
import { useFormComponentContext } from '../../../Hooks/useFormComponentContext';

export type PaymentOptionSelectionProps = {
  paymentOptions: PaymentOption[];
  selectedPaymentOptionId: string;
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
};

export const PaymentOptionSelection: React.FC<PaymentOptionSelectionProps> = ({
  paymentOptions,
  selectedPaymentOptionId,
  numberOfParticipants,
  dateRegionalSettingsLocale,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { isBookingsOnEcom } = useFormComponentContext();
  const { setPaymentOption } = useFormActions();

  const paymentOptionWarnings = getPaymentOptionWarnings({
    paymentOptions,
    numberOfParticipants,
    dateRegionalSettingsLocale,
    t,
  });

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={PaymentOptionsSelectionDataHooks.PAYMENT_OPTIONS_SELECTION}
    >
      {paymentOptionWarnings.map((sectionMessageText) => (
        <SectionMessage
          key={sectionMessageText}
          type={SectionMessageType.Alert}
          text={sectionMessageText}
        />
      ))}
      {isBookingsOnEcom && (
        <Text
          className={classes.label}
          data-hook={
            PaymentOptionsSelectionDataHooks.PAYMENT_OPTIONS_SELECTION_LABEL
          }
          tagName={TagName.Span}
        >
          {getContent({
            settings,
            settingsParam: settingsParams.selectPaymentMethodTitle,
          })}
        </Text>
      )}
      <div className={classes.paymentOptions}>
        {paymentOptions.map((paymentOption) => (
          <PaymentOptionComponent
            key={paymentOption.id!}
            id={paymentOption.id!}
            label={paymentOption.label!}
            selected={paymentOption.id === selectedPaymentOptionId}
            disabled={paymentOption.disabled!}
            suffix={paymentOption.suffix}
            validUntil={paymentOption.validUntil}
            onChange={setPaymentOption}
          />
        ))}
      </div>
    </div>
  );
};

const getPaymentOptionWarnings = ({
  paymentOptions,
  numberOfParticipants,
  dateRegionalSettingsLocale,
  t,
}: {
  paymentOptions: PaymentOption[];
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
  t: TFunction;
}) => {
  let paymentOptionWarnings: string[] = [];
  const pricingPlanOptions = paymentOptions.filter(
    (option: PaymentOption) =>
      option.id !== ReservedPaymentOptionIds.BuyAPricingPlan &&
      option.id !== ReservedPaymentOptionIds.SingleSession,
  );
  const isMembershipPlan = (paymentOption: PaymentOption) =>
    !paymentOption.creditRemain;
  const isPunchCardWithoutEnoughCredit = (paymentOption: PaymentOption) =>
    Number(paymentOption?.creditRemain) < numberOfParticipants;

  const membershipWarningsPlanNames: string[] =
    numberOfParticipants > 1
      ? pricingPlanOptions
          .filter(isMembershipPlan)
          .map((membershiplan: PaymentOption) => membershiplan.label)
      : [];
  const punchCardWarningsPlanNames: string[] = pricingPlanOptions
    .filter(isPunchCardWithoutEnoughCredit)
    .map((punchCardPlan: PaymentOption) => punchCardPlan.label);

  paymentOptionWarnings = [
    ...(membershipWarningsPlanNames.length > 0
      ? [
          t(
            'app.payment.warning.membership-plan-reduce-participants-number.text',
            {
              numberOfPlans: membershipWarningsPlanNames!.length,
              planNames: formatPlanNames(
                membershipWarningsPlanNames,
                dateRegionalSettingsLocale,
              ),
            },
          ),
        ]
      : []),
    ...(punchCardWarningsPlanNames.length > 0
      ? [
          t(
            'app.payment.warning.not-enough-sessions-left-reduce-participants-number.text',
            {
              numberOfPlans: punchCardWarningsPlanNames!.length,
              planNames: formatPlanNames(
                punchCardWarningsPlanNames,
                dateRegionalSettingsLocale,
              ),
            },
          ),
        ]
      : []),
  ];
  return paymentOptionWarnings;
};

const formatPlanNames = (
  planNames: string[],
  dateRegionalSettingsLocale: string,
) => {
  const formatter = new Intl.ListFormat(dateRegionalSettingsLocale, {
    style: 'long',
    type: 'conjunction',
  });
  return formatter.format(planNames);
};
