import React from 'react';
import { SectionNotification } from 'wix-ui-tpa/cssVars';
import AlertIcon from 'wix-ui-icons-common/on-stage/Alert';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import { classes } from './SectionMessage.st.css';
import { SectionMessageHook } from './dataHooks';

export enum SectionMessageType {
  Error = 'error',
  Alert = 'alert',
}

export interface SectionMessageProps {
  text: string;
  type: SectionMessageType;
}

export const SectionMessage: React.FC<SectionMessageProps> = ({
  text,
  type,
}) => {
  const getIcon = () =>
    type === SectionMessageType.Error ? <ErrorIcon /> : <AlertIcon />;

  return text ? (
    <div
      className={classes.root}
      data-hook={SectionMessageHook.Section_Message}
    >
      <SectionNotification
        type={type}
        data-hook={SectionMessageHook.Section_Notification}
      >
        <SectionNotification.Icon icon={getIcon()} />
        <SectionNotification.Text>{text}</SectionNotification.Text>
      </SectionNotification>
    </div>
  ) : null;
};
